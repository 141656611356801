
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('cotton_ginner_grower.approve_schedule') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('warehouse_config.fiscal_year')"
                            label-for="fiscal_year_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col  xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.from_date')"
                        label-for="from_date"
                        >
                        <b-form-input
                            id="datepicker"
                            v-model="search.from_date"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col  xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.to_date')"
                        label-for="to_date"
                        >
                        <b-form-input
                            id="datepicker"
                            v-model="search.to_date"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('cotton_ginner_grower.approve_schedule_list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(zone_name)="data">
                                            {{ data.item.zone_name }}
                                        </template>
                                        <template v-slot:cell(schedule_date)="data">
                                            {{ data.item.schedule_date | dateFormat }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button class="mr-1" title="View Details" v-b-modal.modal-detail variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                                            <!-- <b-button class="mr-1" title="Approve" v-b-modal.modal-1 variant=" iq-bg-success border" size="sm" @click="approve(data.item)"><i class="ri-check-line"></i></b-button> -->
                                            <!-- <b-button title="Reject" v-b-modal.modal-2 variant=" iq-bg-danger border" size="sm" @click="reject(data.item)"><i class="ri-close-line"></i></b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-detail @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Approve" v-b-modal.modal-1 @click="approve(data.item)"><i class="fas fa-check"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Reject" v-b-modal.modal-2 @click="reject(data.item)"><i class="fas fa-times-circle"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-detail" size="lg" :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <DetailModal :id="editItemId" :key="editItemId" :item="item"/>
      </b-modal>
    </b-container>
</template>
<script>
import DetailModal from '../manage-schedule/DetailModal'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { ApproveScheuleList, ManageScheuleApprove, ManageScheuleReject } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    DetailModal
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        from_date: '',
        to_date: ''
      },
      item: '',
      rows: [],
      statusList: [
        {
          value: 1,
          text: 'Pending',
          text_bn: 'অনিষ্পন্ন'
        },
        {
          value: 2,
          text: 'Approved',
          text_bn: 'অনুমোদিত'
        },
        {
          value: 3,
          text: 'Rejected',
          text_bn: 'প্রত্যাখ্যাত'
        }
      ]
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.sort((a, b) => b.sorting_order - a.sorting_order)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('cotton_ginner_grower.approve_schedule_entry') : this.$t('cotton_ginner_grower.approve_schedule') + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('cotton_ginner_grower.region'), class: 'text-center' },
          { label: this.$t('org_pro_district.district'), class: 'text-center' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
          { label: this.$t('cotton_ginner_grower.unit_name'), class: 'text-center' },
          { label: this.$t('cotton_ginner_grower.growers_name'), class: 'text-center' },
          { label: this.$t('globalTrans.date'), class: 'text-center' },
          { label: this.$t('cotton_ginner_grower.hat_name'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'region_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazila_name_bn' },
          { key: 'unit_name_bn' },
          { key: 'name_bn' },
          { key: 'schedule_date' },
          { key: 'hatt_name_bn' },
          { key: 'status_name_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'region_name' },
          { key: 'district_name' },
          { key: 'upazila_name' },
          { key: 'unit_name' },
          { key: 'name' },
          { key: 'schedule_date' },
          { key: 'hatt_name' },
          { key: 'status_name' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    searchData () {
      this.loadData()
    },
    approve (item) {
      this.$swal({
        title: this.$t('globalTrans.approveMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.approveStatus(item)
        }
      })
    },
    async approveStatus (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.deleteData(agriMarketingServiceBaseUrl, ManageScheuleApprove + '/' + item.id, null)
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    reject (item) {
      this.$swal({
        title: this.$t('globalTrans.rejectMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.rejectStatus(item)
        }
      })
    },
    async rejectStatus (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.deleteData(agriMarketingServiceBaseUrl, ManageScheuleReject + '/' + item.id, null)
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriMarketingServiceBaseUrl, ApproveScheuleList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList

      const listData = data.map(item => {
        const fiscaleYearObject = fiscalYearList.find(fiscaleYear => fiscaleYear.value === item.fiscal_year_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazila => upazila.value === item.upazilla_id)
        const statusObject = this.statusList.find(data => data.value === item.status)

        const fiscaleYearData = { fiscal_year_name: fiscaleYearObject?.text_en, fiscal_year_name_bn: fiscaleYearObject?.text_bn }
        const districtData = { district_name: districtObject?.text, district_name_bn: districtObject?.text_bn }
        const upazilaData = { upazila_name: upazilaObject?.text, upazila_name_bn: upazilaObject?.text_bn }
        const statusData = { status_name: statusObject?.text, status_name_bn: statusObject?.text_bn }

        return Object.assign({}, item, fiscaleYearData, districtData, upazilaData, statusData)
      })
      return listData
    }
  }
}
</script>
