<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details">
                  <b-col lg="12" sm="12">
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('globalTrans.details') }}</h5>
                          <b-table striped small bordered hover :items="details_columns"  thead-class="hidden_header">
                            <template v-slot:cell(schedule_date)="data">
                              <span>{{ data.item.schedule_date | dateFormat }}</span>
                            </template>
                          </b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.manage_schedule = this.item
  },
  data () {
    return {
      manage_schedule: '',
      slOffset: 1
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    details_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('globalTrans.company_name'), val: this.manage_schedule.name_bn, key1: this.$t('cotton_ginner_grower.ginnerName'), val1: this.manage_schedule.father_name_bn },
          { key: this.$t('warehouse_config.fiscal_year'), val: this.manage_schedule.fiscal_year_name_bn, key1: this.$t('cotton_ginner_grower.season'), val1: this.manage_schedule.season_name_bn },
          { key: this.$t('cotton_ginner_grower.region'), val: this.manage_schedule.region_name_bn, key1: this.$t('cotton_ginner_grower.zone'), val1: this.manage_schedule.zone_name_bn },
          { key: this.$t('cotton_ginner_grower.unit_name'), val: this.manage_schedule.unit_name_bn, key1: this.$t('cotton_ginner_grower.hat_name'), val1: this.manage_schedule.hatt_name_bn },
          { key: this.$t('org_pro_district.district'), val: this.manage_schedule.district_name_bn, key1: this.$t('org_pro_upazilla.upazilla'), val1: this.manage_schedule.upazila_name_bn },
          { key: this.$t('cotton_config.cotton_variety'), val: this.getCottonVarietyNames(this.manage_schedule.cotton_variety_ids), key1: this.$t('cotton_config.cotton_name'), val1: this.getCottonNames(this.manage_schedule.cotton_ids) },
          { key: this.$t('cotton_ginner_grower.quantity'), val: this.$n(this.manage_schedule.quantity, { useGrouping: false }), key1: this.$t('cotton_ginner_grower.remarks'), val1: this.manage_schedule.remarks_bn },
          { key: this.$t('globalTrans.date'), val: this.$options.filters.dateFormat(this.manage_schedule.schedule_date), key1: this.$t('globalTrans.status'), val1: this.manage_schedule.status_name_bn }
        ]
      } else {
          return [
            { key: this.$t('globalTrans.company_name'), val: this.manage_schedule.name, key1: this.$t('cotton_ginner_grower.ginnerName'), val1: this.manage_schedule.father_name },
            { key: this.$t('warehouse_config.fiscal_year'), val: this.manage_schedule.fiscal_year_name, key1: this.$t('cotton_ginner_grower.season'), val1: this.manage_schedule.season_name },
            { key: this.$t('cotton_ginner_grower.region'), val: this.manage_schedule.region_name, key1: this.$t('cotton_ginner_grower.zone'), val1: this.manage_schedule.zone_name },
            { key: this.$t('cotton_ginner_grower.unit_name'), val: this.manage_schedule.unit_name, key1: this.$t('cotton_ginner_grower.hat_name'), val1: this.manage_schedule.hatt_name },
            { key: this.$t('org_pro_district.district'), val: this.manage_schedule.district_name, key1: this.$t('org_pro_upazilla.upazilla'), val1: this.manage_schedule.upazila_name },
            { key: this.$t('cotton_config.cotton_variety'), val: this.getCottonVarietyNames(this.manage_schedule.cotton_variety_ids), key1: this.$t('cotton_config.cotton_name'), val1: this.getCottonNames(this.manage_schedule.cotton_ids) },
            { key: this.$t('cotton_ginner_grower.quantity'), val: this.$n(this.manage_schedule.quantity, { useGrouping: false }), key1: this.$t('cotton_ginner_grower.remarks'), val1: this.manage_schedule.remarks },
            { key: this.$t('globalTrans.date'), val: this.$options.filters.dateFormat(this.manage_schedule.schedule_date), key1: this.$t('globalTrans.status'), val1: this.manage_schedule.status_name }
          ]
      }
    }
  },
  methods: {
    getCottonVarietyNames (ids) {
      const idArr = JSON.parse(ids)
      if (Array.isArray(idArr)) {
        const arr = []
        idArr.forEach(id => {
          const obj = this.$store.state.agriMarketing.commonObj.cottonVaritiesList.find(item => item.value === parseInt(id))
          if (obj !== undefined) {
            arr.push(obj.text)
          }
        })
        return arr.toString()
      } else {
        const obj = this.$store.state.agriMarketing.commonObj.cottonVaritiesList.find(item => item.value === parseInt(ids))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    },
    getCottonNames (ids) {
      const idArr = JSON.parse(ids)
      if (Array.isArray(idArr)) {
        const arr = []
        idArr.forEach(id => {
          const obj = this.$store.state.agriMarketing.commonObj.cottonNameList.find(item => item.value === parseInt(id))
          if (obj !== undefined) {
            arr.push(obj.text)
          }
        })
        return arr.toString()
      } else {
        const obj = this.$store.state.agriMarketing.commonObj.cottonNameList.find(item => item.value === parseInt(ids))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      }
    }
  }
}
</script>
<style lang="scss">
  .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
